import { render, staticRenderFns } from "./curriculumList.vue?vue&type=template&id=3b9dac84&scoped=true&"
import script from "./curriculumList.vue?vue&type=script&lang=js&"
export * from "./curriculumList.vue?vue&type=script&lang=js&"
import style0 from "./curriculumList.vue?vue&type=style&index=0&id=3b9dac84&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b9dac84",
  null
  
)

export default component.exports