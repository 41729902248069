<template>
  <div class="app-container">
    <div class="filter-container">
      <!-- <el-button
        class="filter-item"
        type="primary"
        @click="handleCreate(false)"
      >
        排课
      </el-button> -->
      <el-input
        v-model="listQuery.school_name"
        placeholder="请输入学校名称"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.course_name"
        placeholder="请输入课程名称"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_on"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="0" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="ID" width="95" align="center">
        <template slot-scope="scope">
          <!-- {{ scope.$index + 1 }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school ? scope.row.school.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="课程" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.other_course ? scope.row.other_course.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="价格" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.other_course ? scope.row.other_course.price : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="课时" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.other_course
              ? scope.row.other_course.total_class_hour
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="上课教师" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher ? scope.row.teacher.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="上课地点" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.classroom ? scope.row.classroom.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="上课时间" min-width="160" align="center">
        <template slot-scope="scope">
          <!-- {{ scope.row.classroom }} -->
          <div
            v-for="(item, index) in scope.row.schedule"
            :key="index"
            class="schedule"
          >
            <div>
              上课星期:{{
                item.week_index == 1
                  ? "星期一"
                  : item.week_index == 2
                  ? "星期二"
                  : item.week_index == 3
                  ? "星期三"
                  : item.week_index == 4
                  ? "星期四"
                  : item.week_index == 5
                  ? "星期五"
                  : item.week_index == 6
                  ? "星期六"
                  : "星期日"
              }}
            </div>
            <div>开始时间:{{ item.start_at }}</div>
            <div>结束时间:{{ item.end_at }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="报名人数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.enroll_count }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="账号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.account }}
        </template>
      </el-table-column> -->
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_on">正常</el-tag>
          <el-tag type="danger" v-else>已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="280"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <!-- <el-button
            size="mini"
            type="primary"
            @click="handleCreate(scope.row.id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            v-if="scope.row.is_on"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            size="mini"
            type="success"
            v-else
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          > -->
          <el-button size="mini" type="danger" @click="Lock(scope.row.id)"
            >查看报名</el-button
          >
          <!-- <el-button size="mini" type="primary" @click="initOne(scope.row.id)">初始化密码</el-button> -->
          <!-- <el-button
            v-if="scope.row.user.is_normal == 1"
            size="mini"
            type="danger"
            @click="handleForbid(scope.row.user_id, '冻结')"
            >冻结</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleForbid(scope.row.user_id, '解冻')"
            >解冻</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="130px">
        <el-form-item label="请输入教师名称:" prop="name">
          <el-input type="text" v-model="form.name" clearable />
        </el-form-item>
        <el-form-item label="请输入教师账号:" prop="account">
          <el-input type="text" v-model="form.account" clearable />
        </el-form-item>
        <el-form-item label="请输入联系电话:" prop="phone">
          <el-input type="text" v-model="form.phone" clearable />
          <!-- <el-select v-model="form.phone" clearable>
            <el-option v-for="(item, index) in school_list" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: [],
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        school_name: "",
        course_name: "",
        is_on: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        id: "",
        name: "",
        account: "",
        phone: "",
      },
      rules: {
        name: [
          { required: true, message: "教师名称不能为空", trigger: "change" },
        ],
        account: [
          { required: true, message: "教师账号不能为空", trigger: "change" },
        ],
        phone: [
          { required: true, message: "联系电话不能为空", trigger: "change" },
        ],
      },
      btnLoading: false,
      school_list: [],
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dialogFormVisible(val) {
      // if (val) this.getSchoolList();
    },
  },
  methods: {
    //  获取学校列表
    getSchoolList() {
      request({
        url: "/api/teacherend/school/list",
        method: "get",
        params: { limit: 9999999 },
      }).then((response) => {
        this.school_list = response.data.data;
      });
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/otherCourse/schoolScheduleList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        account: "",
        phone: "",
      };
      this.btnLoading = false;
    },
    handleCreate(id) {
      this.$router.push(`/E-sportsManagement/curriculumList_edit?id=${id}`);
      // this.resetForm();
      // this.dialogStatus = "create";
      // this.dialogFormVisible = true;
      // this.$nextTick(() => {
      //   this.$refs["form"].clearValidate();
      // });
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/teacherend/school/teacherStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该课程类型, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/otherCourse/schoolScheduleUpDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    // 删除
    delOne(id) {
      this.$confirm("此操作将删除该教师, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/school/teacherDel",
            method: "post",
            data: { id },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    // 初始化
    initOne(id) {
      this.$confirm("此操作将初始化该账号密码, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/school/teacherInitPassword",
            method: "post",
            data: { id },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //冻结
    handleForbid(val, text) {
      this.$confirm("此操作将" + text + "该用户, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/user/forbid",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //分配
    Lock(id) {
      this.$router.push(`/studentManagement/lookApply?id=${id}`);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.schedule {
  background: #e8f4ff;
  color: #1890ff;
  margin-bottom: 10px;
  border-radius: 8px;
}
</style>
